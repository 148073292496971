import React from "react";
import PropTypes from "prop-types";

import GameViewCodeDisplay from "./GameView.CodeDisplay";

const GameViewLink = (props) => {
	const { code } = props;

	if (!code) {
		return null;
	}

	return (
		<>
			<GameViewCodeDisplay code={code} withButton />
			<div className="game-view__link">
				<p>
					<a
						className="game-view__join-button"
						href={`https://jackbox.tv/?code=${code}`}
						target="_blank"
					>
						Join game
					</a>
				</p>
				<p>Clicking the button will send you to Jackbox.tv with the game code already filled in.</p>
				<p>If it is not already filled in, copy the code manually.</p>
			</div>
		</>
	);
};

GameViewLink.displayName = "GameView.Link";

GameViewLink.propTypes = {
	code: PropTypes.string.isRequired
};

export default GameViewLink;

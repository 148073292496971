import { storageGet, storageIsSupported, storageRemove, storageSet } from "./storage";
import { generateRandomString } from "./strings";

const twitchClientId = "4dka7zixe2wb8p7vhlhret6edv0fxt";
const twitchRedirectUri = "https://goldenticket.gg/";
const twitchIdApiRoot = "https://id.twitch.tv/oauth2";
const twitchApiScopes = "";
const twitchUserTokenStorageKey = "twitch-user-token";
const twitchLoginStateStorageKey = "twitch-login-state";

export function getTwitchUserToken() {
	if (!storageIsSupported()) {
		return;
	}

	return storageGet(twitchUserTokenStorageKey);
}

function setTwitchUserToken(token) {
	storageSet(twitchUserTokenStorageKey, token);
}

function twitchLoginUrl(stateString) {
	const redirectUri = window.location.hostname === "localhost"
		? "http://localhost:4080/" : twitchRedirectUri;

	const params = {
		client_id: twitchClientId,
		redirect_uri: redirectUri,
		response_type: "token",
		scope: twitchApiScopes,
		state: stateString
	};

	const queryString = new URLSearchParams(params).toString();
	return `${twitchIdApiRoot}/authorize?${queryString}`;
}

export function redirectToTwitchLogin() {
	const loginState = generateRandomString();
	storageSet(twitchLoginStateStorageKey, loginState);
	window.location.href = twitchLoginUrl(loginState);
}

export function deleteTwitchUserToken() {
	storageRemove(twitchLoginStateStorageKey);
	return storageRemove(twitchUserTokenStorageKey);
}

export function processTwitchRedirect() {
	const { hash } = window.location;

	if (hash.substr(0, 13) !== "#access_token") {
		return;
	}

	if (!storageIsSupported()) {
		console.warn("Local storage not supported");
		return;
	}

	const loginState = storageGet(twitchLoginStateStorageKey);

	if (!loginState) {
		console.warn("No login state set");
		return;
	}

	const params = new URLSearchParams(hash.substr(1));

	if (params.get("state") !== loginState) {
		console.warn("State from Twitch did not match the expected state");
		return;
	}

	setTwitchUserToken(params.get("access_token"));
	storageRemove(twitchLoginStateStorageKey);

	// Remove the hash
	window.history.replaceState({}, document.title, "/");
}

import "./App.scss";

import React, { PureComponent } from "react";

import ConnectedFooter from "../ConnectedFooter";
import ConnectView from "../ConnectView";
import GameView from "../GameView";
import GlobalFooter from "../GlobalFooter";
import LoadingView from "../LoadingView";
import NoGameView from "../NoGameView";
import WaitListView from "../WaitListView";
import socket from "../../lib/realtime";

import { getTwitchUserToken, redirectToTwitchLogin } from "../../lib/twitch";

class App extends PureComponent {
	state = { game: null, loading: true, twitchUser: null };

	componentDidMount() {
		socket.on("connect", this.handleLoaded);
		socket.on("disconnect", this.handleDisconnect);
		socket.on("state", this.handleNewState);
		socket.on("twitch-user-invalid", this.handleTwitchUserTokenInvalid);

		document.addEventListener(
			"visibilitychange",
			this.handleVisibilityChange
		);
	}

	componentWillUnmount() {
		socket.off("connect", this.handleLoaded);
		socket.off("disconnect", this.handleDisconnect);
		socket.off("state", this.handleNewState);
		socket.off("twitch-user-invalid", this.handleTwitchUserTokenInvalid);

		document.removeEventListener(
			"visibilitychange",
			this.handleVisibilityChange
		);
	}

	deleteData = () => {
		this.setState({ game: null, twitchUser: null });
		socket.emit("unauth");
	};

	handleDisconnect = () => {
		this.setState({ loading: true });
	};

	handleLoaded = () => {
		this.setState({ loading: false });
	};

	handleNewState = (state) => {
		this.setState(state);
	};

	handleTwitchUserTokenInvalid = () => {
		// If they have approved the app, they will get auto-redirected back with a new token
		redirectToTwitchLogin();
	};

	handleVisibilityChange = () => {
		if (!document.hidden) {
			// Ask to re-receive current state when we are visible again
			socket.emit("restate");
		}
	};

	render() {
		const { game, loading, twitchUser, waitList } = this.state;
		const twitchUserToken = getTwitchUserToken();

		return (
			<div className="site-container">
				<div className="site-header">
					<h2 className="site-prelude">
						<strong>electricnet</strong> presents
					</h2>
					<h1 className="site-title">
						<img src="/golden-ticket@2x.png" alt="" />{" "}
						GoldenTicket.gg
					</h1>
				</div>
				<div className="site-inner-container">
					{ loading ? (
						<LoadingView />
					) : (
						twitchUserToken ? (
							twitchUser ? (
								<>
									{ game ? (
										<GameView game={game} twitchUser={twitchUser} />
									) : (
										<NoGameView twitchUser={twitchUser} />
									) }
									<WaitListView
										twitchUser={twitchUser}
										waitList={waitList}
									/>
								</>
							) : (
								<LoadingView />
							)
						) : (
							<ConnectView />
						)
					) }
					<div className="site-secondary">
						{ twitchUserToken ? (
							<ConnectedFooter deleteData={this.deleteData} />
						) : null }
						<GlobalFooter />
					</div>
				</div>
			</div>
		);
	}
}

export default App;

const socket = window.io();
let authString = "";
let connected = false;

socket.on("connect", () => {
	connected = true;

	// Authenticate
	if (authString) {
		authenticate();
	}
});

socket.on("disconnect", () => {
	connected = false;
});

function authenticate() {
	socket.emit("auth", authString);
}

export function setAuthString(str) {
	authString = str;

	if (connected) {
		authenticate();
	}
}

export default socket;

import "./main.scss";

import React from "react";
import { render } from "react-dom";

import App from "./components/App";
import { setAuthString } from "./lib/realtime";
import { getTwitchUserToken, processTwitchRedirect } from "./lib/twitch";

processTwitchRedirect();
setAuthString(getTwitchUserToken());

const main = document.querySelector("main");

if (main) {
	render(<App />, main);
} else {
	console.warn("No container for React! :(");
}

const localStorage = window.localStorage;
const storageNameSpace = "epjb-site";

function keyName(key) {
	return `${storageNameSpace}-${key}`;
}

export function storageGet(key) {
	return localStorage && localStorage.getItem(keyName(key));
}

export function storageSet(key, value) {
	return localStorage && localStorage.setItem(keyName(key), value);
}

export function storageRemove(key) {
	return localStorage && localStorage.removeItem(keyName(key));
}

export function storageIsSupported() {
	if (!localStorage) {
		return false;
	}

	try {
		storageSet("test", "1");
		storageRemove("test");
		return true;
	}

	catch (e) {
		// Nothing, returns false below anyway
	}

	return false;
}

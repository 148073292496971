function getRandomInt(min, max) {
	// The maximum is exclusive and the minimum is inclusive
	const mn = Math.ceil(min);
	const mx = Math.floor(max);

	return Math.floor(Math.random() * (mx - mn) + mn);
}

export function generateRandomString(length = 60) {
	const alpha = "0123456789abcdefghijklmnopqrstuvwxyz";
	let out = "";

	for (var i = 0; i < length; i++) {
		out += alpha[getRandomInt(0, alpha.length)];
	}

	return out;
}

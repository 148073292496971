function pad(num) {
	return num < 10 ? "0" + num : "" + num;
}

function twelveHour(hours) {
	let h = hours % 12;

	if (h === 0) {
		return 12;
	}

	return h;
}

function timestamp(date) {
	const h = date.getHours();
	const m = date.getMinutes();
	const s = date.getSeconds();
	const suffix = h >= 12 ? "PM" : "AM";

	return `${twelveHour(h)}:${pad(m)}:${pad(s)} ${suffix}`;
}

exports.timestamp = timestamp;

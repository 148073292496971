import React from "react";
import PropTypes from "prop-types";

const className = "ticket-counter";

// NOTE: This component is almost identical to the one in epjb-overlay

export function getTicketCount({ gameInfo, overrideFull, tickets, winnerSpot }) {
	const ticketCount = tickets.length;

	if (gameInfo && gameInfo.maxPlayers) {
		const remainingSpots = overrideFull ? 0 : Math.max(0,
			gameInfo.maxPlayers - ticketCount - !!winnerSpot
		);

		return { amount: remainingSpots, type: "remaining" };
	}

	return { amount: ticketCount, type: "redeemed" };
}

const TicketCounter = (props) => {
	const {
		gameInfo,
		overrideFull,
		tickets,
		winnerSpot
	} = props;

	const { amount, type } = getTicketCount({
		gameInfo,
		overrideFull,
		tickets,
		winnerSpot
	});

	let label = "";

	if (type === "remaining") {
		label = amount === 1 ? "spot available" : "spots available";
	} else {
		label = amount === 1 ? "ticket redeemed" : "tickets redeemed";
	}

	return (
		<div className={`${className} ${className}--${type}`}>
			<strong>{ amount }</strong>{" "}
			<span>{ label }</span>
		</div>
	);
};

TicketCounter.propTypes = {
	gameInfo: PropTypes.object,
	overrideFull: PropTypes.bool,
	tickets: PropTypes.array,
	winnerSpot: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

export default TicketCounter;

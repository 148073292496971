import React from "react";
import PropTypes from "prop-types";

import Item from "./WaitListView.Item";
import TicketCounter, { getTicketCount } from "../TicketCounter/TicketCounter";

const rcn = "wait-list-view";

function winnerSpotTicket(winnerSpotName) {
	if (!winnerSpotName) {
		return null;
	}

	const name = typeof winnerSpotName == "boolean" ? "Winner" : winnerSpotName;

	return {
		id: null,
		manual: true,
		manualType: "winner",
		rewardId: null,
		rewardName: "Winner spot",
		time: null,
		userName: name,
		userDisplayName: name
	};
}

const WaitListViewGame = (props) => {
	const {
		gameInfo,
		myUserName,
		overrideFull,
		tickets,
		ticketsOpen,
		winnerSpot
	} = props;

	const ticketCount = getTicketCount({ gameInfo, overrideFull, tickets, winnerSpot });
	const isFull = ticketCount.type === "remaining" && ticketCount.amount === 0;
	const statusWarning = !ticketsOpen && !isFull;

	let statusString = `Tickets ${ticketsOpen ? "open" : "closed"}`;

	if (isFull) {
		statusString = "Full game";
	}

	const statusClassName = statusWarning ? `${rcn}__status-warning` : undefined;
	let list = tickets;

	if (winnerSpot) {
		// Visually incorporate winner spot into list of people in game
		list = [
			winnerSpotTicket(winnerSpot),
			...(tickets || [])
		];
	}

	return (
		<div className={`${rcn}__game`}>
			<div className={`${rcn}__game-head`}>
				<h3>{ gameInfo?.name || "Unknown game" }</h3>
				<div className={statusClassName}>{ statusString }</div>
				<TicketCounter
					gameInfo={gameInfo}
					overrideFull={overrideFull}
					tickets={tickets}
					winnerSpot={winnerSpot}
				/>
			</div>
			<ul className={`${rcn}__list`}>
				{ list?.length > 0 ? list.map((ticket, i) => (
					<Item
						{...ticket}
						gameInfo={gameInfo}
						index={i}
						myUserName={myUserName}
						key={ticket.id || i}
					/>
				)) : (
					<li className={`${rcn}__item`}><em>No tickets</em></li>
				) }
			</ul>
		</div>
	);
};

WaitListViewGame.displayName = "WaitListView.Game";

WaitListViewGame.propTypes = {
	gameInfo: PropTypes.object,
	myUserName: PropTypes.string,
	overrideFull: PropTypes.bool,
	tickets: PropTypes.array,
	ticketsOpen: PropTypes.bool,
	winnerSpot: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

export default WaitListViewGame;

import "./GameView.scss";

import React from "react";
import PropTypes from "prop-types";

import GameViewCodeDisplay from "./GameView.CodeDisplay";
import GameViewLink from "./GameView.Link";
import { jackboxTvHashSupported } from "../../lib/jackboxTv";

const GameView = (props) => {
	const { game, twitchUser } = props;

	if (!game || !twitchUser) {
		return null;
	}

	const { code } = game;
	const { displayName: userDisplayName } = twitchUser;
	const gameName = game.name || game.tag;

	return (
		<div className="game-view">
			<div className="site-primary">
				<p>Hey <strong>{ userDisplayName }</strong>, there&#8217;s a game of <strong>{ gameName }</strong> ready for you!</p>
				{ jackboxTvHashSupported ? (
					<GameViewLink code={code} />
				) : (
					<GameViewCodeDisplay code={code} />
				) }
				<div className="site-secondary-text">
					<p>Just make sure you&#8217;re logged in with Twitch on Jackbox.tv, and you&#8217;re ready to click &#8220;Play&#8221;!</p>
					<p>Please do not pass on the game code to anyone.</p>
				</div>
			</div>
		</div>
	);
};

GameView.propTypes = {
	game: PropTypes.shape({
		code: PropTypes.string,
		name: PropTypes.string,
		tag: PropTypes.string
	}).isRequired,
	twitchUser: PropTypes.shape({
		displayName: PropTypes.string
	}).isRequired
};

export default GameView;

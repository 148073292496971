import "./WaitListView.scss";

import React from "react";
import PropTypes from "prop-types";

import Game from "./WaitListView.Game";

const rcn = "wait-list-view";

const WaitListView = (props) => {
	const { twitchUser, waitList } = props;

	if (!waitList) {
		return null;
	}

	const myUserName = twitchUser?.name;

	return (
		<div className={rcn}>
			<h2 className={`${rcn}__heading`}>Current wait list</h2>
			{ waitList.length > 0 ? waitList.map((game) => (
				<Game
					{...game}
					myUserName={myUserName}
					key={game.index}
				/>
			)) : (
				<p>No tickets currently</p>
			) }
		</div>
	);
};

WaitListView.propTypes = {
	twitchUser: PropTypes.shape({
		name: PropTypes.string
	}),
	waitList: PropTypes.array
};

export default WaitListView;

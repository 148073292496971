import React from "react";
import PropTypes from "prop-types";

const NoGameView = (props) => {
	const { twitchUser } = props;

	if (!twitchUser) {
		return null;
	}

	const { displayName: userDisplayName } = twitchUser;

	return (
		<div className="game-view game-view--no">
			<div className="site-primary">
				<p>Hey <strong>{ userDisplayName }</strong>, there&#8217;s currently no game ready for you.</p>
				<p>When one is ready, it will show up automatically here. If you do not see it, try reloading the page.</p>

				<div className="site-secondary-text">
					<p>Get guaranteed access to the next game on electricnet&#8217;s stream by redeeming either <strong>Golden Ticket</strong> or <strong>Save My Seat</strong> channel point redemptions.</p>
					<p>Have already redeemed? Lean back, relax, enjoy the steam, and wait for the game to become available here.</p>
				</div>
			</div>
		</div>
	);
};

NoGameView.propTypes = {
	twitchUser: PropTypes.shape({
		displayName: PropTypes.string
	}).isRequired
};

export default NoGameView;

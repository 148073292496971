import React, { useRef, useState } from "react";
import PropTypes from "prop-types";

function selectAll(evt) {
	if (evt.target) {
		evt.target.select();
	}
}

const GameViewCodeDisplay = (props) => {
	const { code, withButton } = props;

	if (!code) {
		return null;
	}

	const [copied, setCopied] = useState(false);
	const inputEl = useRef(null);

	const copyCodeViaExec = () => {
		if (inputEl && inputEl.current) {
			inputEl.current.select();
			document.execCommand("copy");
			setCodeCopied();
		}
	};

	const setCodeCopied = () => {
		// Display "copied" notification
		setCopied(true);
		setTimeout(() => setCopied(false), 2000);
	};

	const copyCode = async () => {
		if (navigator.clipboard) {
			try {
				await navigator.clipboard.writeText(code);
				setCodeCopied();
			} catch (e) {
				copyCodeViaExec();
			}
		}

		else {
			copyCodeViaExec();
		}
	};

	const copiedClassName = "game-view__code-copied" +
		(copied ? " game-view__code-copied__visible" : "");

	return (
		<div className="game-view__code-display">
			<p>The code for the game is:</p>
			<div className="game-view__code-container">
				<input
					readOnly
					ref={inputEl}
					className="game-view__code"
					onClick={selectAll}
					value={code}
				/>
				<button
					className="game-view__copy-button"
					onClick={copyCode}
					title="Copy the code to the clipboard"
				>
					Copy
				</button>
				<span className={copiedClassName}>Code copied!</span>
			</div>
			{!withButton && (
				<p>
					<a
						className="game-view__text-link"
						href="https://jackbox.tv/"
						target="_blank"
					>
						Go to Jackbox.TV
					</a>
					{" and use the code to join the game."}
				</p>
			)}
		</div>
	);
};

GameViewCodeDisplay.displayName = "GameView.CodeDisplay";

GameViewCodeDisplay.propTypes = {
	code: PropTypes.string.isRequired,
	withButton: PropTypes.bool
};

GameViewCodeDisplay.defaultProps = {
	withButton: false
};

export default GameViewCodeDisplay;

import React from "react";
import PropTypes from "prop-types";

import { deleteTwitchUserToken } from "../../lib/twitch";

const ConnectedFooter = ({ deleteData }) => {
	const onDelete = (evt) => {
		evt.preventDefault();
		deleteTwitchUserToken();
		deleteData();
	};

	return (
		<div className="connected-footer">
			<p>
				<a href="#" onClick={onDelete}>
					Click here to disconnect your Twitch account from the site (log out)
				</a>
			</p>
		</div>
	);
};

ConnectedFooter.propTypes = {
	deleteData: PropTypes.func.isRequired
};

export default ConnectedFooter;

import React from "react";
import PropTypes from "prop-types";

import { timestamp } from "../../lib/time";

const rcn = "wait-list-view";
const icn = `${rcn}__item`;

const WaitListViewItem = (props) => {
	const {
		gameInfo,
		giftUserName,
		index,
		myUserName,
		rewardName,
		time,
		userDisplayName,
		userName
	} = props;

	const displayedTime = time ? timestamp(new Date(time)) : "";
	const gameName = gameInfo?.name;
	const isMine = myUserName && (
		(userName.toLowerCase() === myUserName.toLowerCase() && !giftUserName) ||
		(giftUserName && giftUserName.toLowerCase() === myUserName.toLowerCase())
	);
	const className = icn + (isMine ? ` ${icn}--mine` : "");

	return (
		<li className={className} title={isMine ? "This is your ticket!" : undefined}>
			<span className={`${icn}__index`}>
				{ `${1 + index}. ` }
			</span>
			{ displayedTime && (
				<>
					<time
						className={`${icn}__time`}
						dateTime={time}
					>
						{displayedTime}:
					</time>{" "}
				</>
			) }
			{ giftUserName && (
				<span className={`${icn}__giftusername`}>
					<strong>{ giftUserName }</strong>
					{" by "}
				</span>
			) }
			<strong className={`${icn}__username`} title={userName}>
				{ userDisplayName }
			</strong>{" "}
			{ gameName && (
				<>
					<span className={`${icn}__game`}>
						{ gameName }
					</span>{" "}
				</>
			) }
			<span className={`${icn}__reward`}>
				{ rewardName }
			</span>
		</li>
	);
};

WaitListViewItem.displayName = "WaitListView.Item";

WaitListViewItem.propTypes = {
	gameInfo: PropTypes.object,
	giftUserName: PropTypes.string,
	index: PropTypes.number.isRequired,
	myUserName: PropTypes.string,
	rewardName: PropTypes.string,
	time: PropTypes.string,
	userName: PropTypes.string.isRequired,
	userDisplayName: PropTypes.string.isRequired
};

export default WaitListViewItem;

import "./LoadingView.scss";

import React from "react";

const LoadingView = () => {
	return (
		<div className="loading-view">
			<div className="site-primary">
				<p className="loading-view__loading">Loading&#8230;</p>
			</div>
		</div>
	);
};

export default LoadingView;

import "./ConnectView.scss";

import React from "react";

import { storageIsSupported } from "../../lib/storage";
import { redirectToTwitchLogin } from "../../lib/twitch";

const ConnectView = () => {
	return (
		<div className="connect-view">
			{ !storageIsSupported() && (
				<div className="connect-view__warning">
					<strong>Heads up:</strong> Your browser does not support local storage, and you will not be able to connect to Twitch. Please upgrade to a newer browser to use this site. Sorry about this!
				</div>
			) }
			<div className="site-primary">
				<p><strong>No more waiting for Twitch whispers.</strong> Instantly get access to Jackbox games on Elec&#8217;s stream that you have redeemed access to!</p>
				<p><strong>It&#8217;s super easy!</strong> You will see a button to join the Jackbox game you have redeemed access to!</p>
				<p>Use the button below to get started:</p>
				<button
					className="connect-view__connect-button"
					onClick={redirectToTwitchLogin}
				>
					Connect to Twitch
				</button>
				<div className="site-secondary-text">
					<p>The site only queries your publicly available Twitch user information as a way to identify you. Your Twitch user data is not kept permanently on the server.</p>
				</div>
			</div>
		</div>
	);
};

export default ConnectView;

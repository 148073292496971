import React from "react";

const GlobalFooter = () => {
	return (
		<div className="global-footer">
			<p>This service is created by andylectric, and is not affiliated with Jackbox Games or Twitch.tv.</p>
		</div>
	);
};

export default GlobalFooter;
